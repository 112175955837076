import BrowserBreadcrumb from './BreadCrumb'
import React from "react";
import {useLocation} from "react-router-dom"


const SearchBox = () => {
// ({styleName, placeholder, onChange, value}) => {
//   const location = useLocation();
//   console.log(location)
  return (
    <div className={`gx-search-bar`}>
      <div className="gx-form-group">
      <BrowserBreadcrumb/>
        {/* <input className="ant-input" type="search" placeholder={placeholder} onChange={onChange}
               value={value}/> */}
        {/* <span className="gx-search-icon gx-pointer"><i className="icon icon-search"/></span> */}
      </div>
     
    </div>
  )
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
