import axios from 'axios';

export const httpClient = axios.create({
  // baseURL: `http://aaronsoftech.in:8080/api/admin`, //YOUR_API_URL HERE
  baseURL: `https://db-dev-api.aaronsoftech.in/api/admin`,
  // baseURL: `http://127.0.0.1:8080/api/admin`,
  // baseURL: `http://lms.arambh.tech/api/admin`, 
  headers: {
    'Content-Type': 'application/json',
  },
});
