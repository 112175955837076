import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./Dashboard/index'))}/>
      {/* <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/> */}
      <Route path={`/usermanage`} exact component={asyncComponent(() => import('./usermanage/index'))}/>
      <Route path={`/usermanage/:id`} exact component={asyncComponent(() => import('./usermanage/[id]'))}/>
      <Route path={`/blog`} exact component={asyncComponent(() => import('./blog'))}/>
      <Route path={`/blog/:id`} exact component={asyncComponent(() => import('./blog/[id]'))}/>
      <Route path={`/banner`} exact component={asyncComponent(() => import('./banner'))}/>
      <Route path={`/banner/:id`} exact component={asyncComponent(() => import('./banner/[id]'))}/>
      <Route path={`/exam`} exact component={asyncComponent(() => import('./exam'))}/>
      <Route path={`/exam/:id`} exact component={asyncComponent(() => import('./exam/[id]'))}/>
      <Route path={`/course`} exact component={asyncComponent(() => import('./course'))}/>
      <Route path={`/course/:id`} exact component={asyncComponent(() => import('./course/[id]'))}/>
      <Route path={`/subject`} exact component={asyncComponent(() => import('./subject'))}/>
      <Route path={`/subject/:id`} exact component={asyncComponent(() => import('./subject/[id]'))}/>
      <Route path={`/material`} exact component={asyncComponent(() => import('./material'))}/>
      <Route path={`/material/:id`} exact component={asyncComponent(() => import('./material/[id]'))} />
      <Route path={`/Category`} exact component={asyncComponent(() => import('./Category'))}/>
      <Route path={`/Category/:id`} exact component={asyncComponent(() => import('./Category/[id]'))} />
      <Route path={`/Payment`} exact component={asyncComponent(() => import('./Payment'))}/>
      <Route path={`/Payment/:id`} exact component={asyncComponent(() => import('./Payment/[id]'))}/>
      {/* <Route path={`/Subscription`} exact component={asyncComponent(() => import('./Subscription'))}/> */}
      {/* <Route path={`/Subscription/:id`} exact component={asyncComponent(() => import('./Subscription/[id]'))} /> */}
      <Route path={`/university`} exact component={asyncComponent(() => import('./University'))}/>
      <Route path={`/university/:id`} exact component={asyncComponent(() => import('./University/[id]'))}/>
      <Route path={`/instructor`} exact component={asyncComponent(() => import('./Instructor'))}/>
      <Route path={`/instructor/:id`} exact component={asyncComponent(() => import('./Instructor/[id]'))}/>
      <Route path={`/testimonials`} exact component={asyncComponent(() => import('./Testimonials'))}/>
      <Route path={`/testimonials/:id`} exact component={asyncComponent(() => import('./Testimonials/[id]'))}/>
      <Route path={`/state`} exact component={asyncComponent(() => import('./State'))}/>
      <Route path={`/state/:id`} exact component={asyncComponent(() => import('./State/[id]'))} />
      <Route path={`/country`} exact component={asyncComponent(() => import('./Country'))}/>
      <Route path={`/country/:id`} exact component={asyncComponent(() => import('./Country/[id]'))} />
      <Route path={`/plan`} exact component={asyncComponent(() => import('./Plan'))}/>
      <Route path={`/plan/:id`} exact component={asyncComponent(() => import('./Plan/[id]'))}/>
      <Route path={`/quiz`} exact component={asyncComponent(() => import('./Quiz'))}/>
      <Route path={`/quiz/:id`} exact component={asyncComponent(() => import('./Quiz/[id]'))}/>
      <Route path={`/quizdetail`} exact component={asyncComponent(() => import('./QuizDetail'))}/>
      <Route path={`/quizdetail/:id`} exact component={asyncComponent(() => import('./QuizDetail/[id]'))}/>
      <Route path={`/quizdetail/multiple/:id`} exact component={asyncComponent(() => import('./QuizDetail/[slug]'))}/>
      <Route path={`/smartvideo`} exact component={asyncComponent(() => import('./SmartVideo'))}/>
      <Route path={`/smartvideo/:id`} exact component={asyncComponent(() => import('./SmartVideo/[id]'))}/>
      <Route path={`/smartvideodetail`} exact component={asyncComponent(() => import('./SmartVideoDetail'))}/>
      <Route path={`/smartvideodetail/:id`} exact component={asyncComponent(() => import('./SmartVideoDetail/[id]'))}/>
      <Route path={`/review`} exact component={asyncComponent(() => import('./Review'))}/>
      <Route path={`/faq`} exact component={asyncComponent(() => import('./Faq'))}/>
      <Route path={`/faq/:id`} exact component={asyncComponent(() => import('./Faq/[id]'))}/>

    </Switch>
  </div>
);

export default App;
