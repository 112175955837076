import { useEffect, useState } from 'react';
import {httpClient} from "../../../util/Api";
import { Cookies } from "react-cookie";

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (data, callbackFun) => {
    fetchStart();
    httpClient.post('user/login', data)
      .then(({ data }) => {
        console.log(data);
        if (data && data.code == 200) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] =  data?.data?.token;
          const cookies = new Cookies();
          cookies.set('token', data?.data?.token);
          getAuthUser(data?.data);
          if (callbackFun) return callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post('auth/register', user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          localStorage.setItem('token', data.token.access_token);
          httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    httpClient
      .post('auth/logout')
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          httpClient.defaults.headers.common['Authorization'] = '';
          localStorage.removeItem('token');
          setAuthUser(false);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAuthUser = (data) => {
    fetchStart();
    httpClient.get("user/profile").then(({ data }) => {
      
      console.log("data",data)
      if (data && data?.code == 200) {
        fetchSuccess();
        // const cookies = new Cookies();
        // const token = cookies.get("token");
        // httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        // router.push('/')
        setAuthUser(data?.data);
      } else {
        fetchError(data.error);
      }
    }).catch(function (error) {
      httpClient.defaults.headers.common['Authorization'] = '';
      fetchError(error.message);
    });
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    httpClient.defaults.headers.common['Authorization'] = token;

  
  httpClient.get("user/profile").then(({ data }) => {
      console.log(data);
      if (data?.data) {
        setAuthUser(data?.data);
      }
      setLoadingUser(false);
    }).catch(function (error) {
      cookies.remove('token');
      httpClient.defaults.headers.common['Authorization'] = '';
      setLoadingUser(false);
    });
  // if (token) {
  //   console.log('token', token);
  //   setLoadingUser(false);
  //   router.push('/')
  //   // navigate('/')
    
  // } else {
  //   // history.push('/signin')
  //   setLoadingUser(false);
  //   router.push('/signin')
  //   // window.location.reload('/')
  // }
  }, []
);


  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
