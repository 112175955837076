import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {navStyle, themeType} = useSelector(({settings}) => settings);
  const pathname = useSelector(({common}) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          {/* <UserProfile/> */}
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {/* <Menu.Item key="sample">
              <Link to="/dashboard"><i className="icon icon-widgets"/>
                <span><IntlMessages id="sidebar.dashboard"/></span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="usermanage">
              <Link to="/usermanage"><i className="icon icon-user"/>
                <span><IntlMessages id="sidebar.user"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="blog">
              <Link to="/blog"><i className="icon icon-star"/>
                <span><IntlMessages id="sidebar.blog"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="banner">
              <Link to="/banner"><i className="icon icon-tag"/>
                <span><IntlMessages id="sidebar.banner"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="exam">
              <Link to="/exam"><i className="icon icon-wall"/>
                <span><IntlMessages id="sidebar.exam"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="quiz">
              <Link to="/quiz"><i className="icon icon-forward-o"/>
                <span><IntlMessages id="sidebar.Quiz"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="quizdetail">
              <Link to="/quizdetail"><i className="icon icon-forward-o"/>
                <span><IntlMessages id="sidebar.Quizdetail"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="course">
              <Link to="/course"><i className="icon icon-queries"/>
                <span><IntlMessages id="sidebar.course"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="subject">
              <Link to="/subject"><i className="icon icon-transfer"/>
                <span><IntlMessages id="sidebar.subject"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="material">
              <Link to="/material"><i className="icon icon-tasks"/>
                <span><IntlMessages id="sidebar.material"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="category">
              <Link to="/Category"><i className="icon icon-view-o"/>
                <span><IntlMessages id="sidebar.category"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="payment">
              <Link to="/payment"><i className="icon icon-view-o"/>
                <span><IntlMessages id="sidebar.payment"/></span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="subscription">
              <Link to="/subscription"><i className="icon icon-view-o"/>
                <span><IntlMessages id="sidebar.subscription"/></span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="university">
              <Link to="/university"><i className="icon icon-map-overlay"/>
                <span><IntlMessages id="sidebar.university"/></span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="Instructor">
              <Link to="/Instructor"><i className="icon icon-map-event-listener"/>
                <span><IntlMessages id="sidebar.instructor"/></span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="testimonials">
              <Link to="/testimonials"><i className="icon icon-inputnumber"/>
                <span><IntlMessages id="sidebar.testimonials"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="state">
              <Link to="/state"><i className="icon icon-home"/>
                <span><IntlMessages id="sidebar.state"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="country">
              <Link to="/country"><i className="icon icon-geo-location"/>
                <span><IntlMessages id="sidebar.country"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="plan">
              <Link to="/plan"><i className="icon icon-forward-o"/>
                <span><IntlMessages id="sidebar.Plan"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="instructor">
              <Link to="/instructor"><i className="icon icon-forward-o"/>
                <span><IntlMessages id="sidebar.instructor"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="smartvideo">
              <Link to="/smartvideo"><i className="icon icon-geo-location"/>
                <span><IntlMessages id="sidebar.SmartVideo"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="smartvideodetail">
              <Link to="/smartvideodetail"><i className="icon icon-geo-location"/>
                <span><IntlMessages id="sidebar.SmartVideoDetail"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="review">
              <Link to="/review"><i className="icon icon-forward-o"/>
                <span><IntlMessages id="sidebar.Review"/></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="fAQ">
              <Link to="/faq"><i className="icon icon-forward-o"/>
                <span><IntlMessages id="sidebar.Faq"/></span>
              </Link>
            </Menu.Item>

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

