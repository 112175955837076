import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

const BrowserBreadcrumb = () => {
  const [pathSegments, setPathSegments] = useState([]);
  const path = window.location.pathname;

  useEffect(() => {
    // Parse the browser URL to get path segments
    const segments = path.split('/').filter((segment) => segment !== '');
    setPathSegments(segments);
  }, [path]);

  return (
    <Breadcrumb style={{ fontSize: '22px' }}>
      <Breadcrumb.Item key="home">
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      {pathSegments.map((segment, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={'/' + pathSegments.slice(0, index + 1).join('/')}>
            {segment}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BrowserBreadcrumb;
